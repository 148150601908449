/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import { uniqueId } from "lodash"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import {
  Button,
  SEO,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero } from "../components/elements"

const Careers = ({ data }) => {
  const page = data.prismic.careers

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={["full", 4 / 5, 3 / 4]}
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
              }}
            >
              {RichText.render(page.content)}
              <a
                href={page.printable_application.url}
                sx={{ textColor: "white" }}
              >
                <Button variant="simple" sx={{ mb: 4 }}>
                  Download/Print Application
                </Button>
              </a>

              {page.openings &&
                page.openings.map(({ job_description, job_title }) => (
                  <Box key={`${uniqueId("job_id_")}`}>
                    {RichText.render(job_title)}
                    {RichText.render(job_description)}
                    <hr />
                  </Box>
                ))}
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Careers

export const query = graphql`
  query CareersQuery {
    prismic {
      careers(lang: "en-us", uid: "careers") {
        title
        content
        meta_title
        meta_description
        _linkType
        openings {
          job_description
          job_title
        }
        printable_application {
          _linkType
          ... on PRISMIC__FileLink {
            _linkType
            name
            url
          }
        }
      }
    }
  }
`
